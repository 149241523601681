(function() {
    "use strict";
    angular.module("wbUtilities").constant("CommonConstant", {
            devDomains: ['192.168.1.4', '127.0.0.1', 'localhost', 'staging-www.wisebanyan.com', 'staging.wisebanyan.com', 'staging-www.axosinvest.com', 'staging.axosinvest.com'],
            google: {
                //client_id from adam.segal@wisebanyan.com:
                apiClientId: "981501170924-k5tdfltt9m1mo1gavm57b3jpk6dsrkha.apps.googleusercontent.com",

                reCaptchaKeyV3: "6Lc4hlgUAAAAAG06oHF5oXBoYEUM5m6mcWxLZy75",
                reCaptchaKeyV2: "6Lf6iFgUAAAAAPvqSq3Yc6_Blof6sWZ5tcE6c_xm",
                reCaptchaKeyInvisible: "6LcM61wUAAAAALkpODEme4TT6svTPwmVbo4Wcw3T",
            },
            googleTest: {
                //https://console.developers.google.com/apis/credentials?project=wisebanyan-148817
                apiClientId: "751845714776-5skflqdcgf017d5lau95qagu1mvflc3n.apps.googleusercontent.com",
                reCaptchaKey: "",
            },
            bugsnagApiKey: "0f81e94d64ebe7ff4464865addf5ae8a",
            plaid: {
                clientName: "Axos Invest Managed Portfolios",
                linkCustomizationName: "axos_invest",
                // webhook: "", //shouldn't be empty, manual custom url entered in use
                env: "sandbox",
                // key: "262e11ec91ec1cba37c0eae9c04c60", //no longer needed
                product: "auth",
                apiVersion: "v2"
            },
            haven: {
                url: "https://partners.ci.dev.havenlife.com/amorphic/init/customer.js"
            },
            site: {
				api_gateway_key: "LVip0SsrGh9yKtLzhpaVi7quZIpW7UaG5Zw1TGOE",
				signature_secret: "svLCjAxc8ucga29jSsk2",
                wbBeta: false,
			},
            build: "300",
            buildVersion: "4a1ceff5210486f6c0d9cfd1fd111ad988678271",
            environment: "staging"
        });
})();
